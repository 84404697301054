export class FileTypeData {
  public static data = [
    {id: 'parameter', valor: 'Parameter'},
    {id: 'user', valor: 'User'},
    {id: 'bgc', valor: 'Bgc'},
    {id: 'sci', valor: 'Sci'},
    {id: 'unsheActivitie', valor: 'UnsheduledActivitie'},
  ];
}

