export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBU2W2HjzhTouWlBKzGsbCXARo4Dd_U7Cw",
    authDomain: "fairoz-web-c695f.firebaseapp.com",
    databaseURL: "https://fairoz-web-c695f.firebaseio.com",
    projectId: "fairoz-web-c695f",
    storageBucket: "fairoz-web-c695f.appspot.com",
    messagingSenderId: "377979705229",
    appId: "1:377979705229:web:b2fe409a80e2d9f5844fc4",
    measurementId: "G-HK0M2NTTEC"
  },
  apiProtocol: 'https',
  apiServer: 'localhost',
  apiPath: 'api',
  apiPort: 44366,
};
