import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-layout-home',
  templateUrl: './layout-home.component.html',
  styleUrls: ['./layout-home.component.sass']
})
export class LayoutHomeComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {
  }

  hiddeScroll(e){
  	  let scrollOffset = e.srcElement.children[0].scrollTop;

  	  if(scrollOffset > 56){
        (document.querySelector('.#menu') as HTMLElement).style.display = 'none';
  	  }
  	  else{
        (document.querySelector('.#menu') as HTMLElement).style.display = 'block'
  	  }

  }
}
